import React from "react"
import styles from "./styles.scss"
import AOS from "aos"
import "aos/dist/aos.css"
function HeroSvg() {
	if (typeof window !== "undefined") {
		window.addEventListener("load", AOS.refresh())
		AOS.init({
			once: true,
			mirror: true,
		})
	}
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			data-name="Layer 1"
			viewBox="0 0 1679.971 898.512"
			data-aos="fade-up"
			data-aos-once="true"
			data-aos-offset="100"
			data-aos-easing="ease-in-out"
			data-aos-duration="1500"
			data-aos-mirror="true"
			className="heroSvg"
		>
			<path
				d="M1704.729 893.986L306.259 793.564a72.591 72.591 0 01-67.39-71.874l-1.78-243.015a72.591 72.591 0 0162.288-72.389l1400.25-200.744a72.591 72.591 0 0182.893 71.857V821.58a72.591 72.591 0 01-77.791 72.405z"
				className="cls-1"
				transform="translate(-102.549 -61)"
			></path>
			<path
				d="M1647.344 740.601l-1257.64 129.92c-33.968 3.51-65.65-22.466-72.766-59.66l-45.67-238.692c-6.825-35.666 11.146-70.426 42.07-81.372L1517.91 64.419c37.63-13.32 78.486 13.653 86.503 57.11l98.737 535.149c7.645 41.439-18.009 80.018-55.807 83.923z"
				className="cls-1"
				transform="translate(-102.549 -61)"
			></path>
			<path
				d="M597.168 948.965L873.74 164.82a15.797 15.797 0 00-19.142-20.471L114.112 350.885a15.797 15.797 0 00-8.073 25.108l463.914 577.61a15.797 15.797 0 0027.215-4.638z"
				className="cls-1"
				transform="translate(-102.549 -61)"
			></path>
			<text className="cls-2" transform="translate(189.76 459.796)">
				TRUST
			</text>
			<text className="cls-3" transform="translate(212.439 516.62)">
				Y
				<tspan x="44.72" y="0" className="cls-4">
					OUR
				</tspan>
			</text>
			<text className="cls-5" transform="translate(446.871 556.266)">
				BILLION
			</text>
			<text className="cls-5" transform="translate(448.34 651.48)">
				DOLLAR
			</text>
			<text className="cls-6" transform="translate(960.42 650.52)">
				IDEA
			</text>
		</svg>
	)
}

export default HeroSvg
