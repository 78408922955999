import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import * as styles from "./styles.module.scss"
import { HomePage } from "../../../constants/index"
import AOS from "aos"
const Welcome = () => {
	const welcome = HomePage.welcome
	if (typeof window !== "undefined") {
		window.addEventListener("load", AOS.refresh())
		AOS.init({
			once: true,
			mirror: true,
		})
	}
	return (
		<>
			<div className={styles.maindiv}>
				<Container className={styles.welContainer}>
					<Row
						data-aos="fade-left"
						data-aos-once="true"
						data-aos-offset="100"
						data-aos-easing="ease-in-out"
						data-aos-duration="1500"
						data-aos-mirror="true"
					>
						<Col md={6} sm={12} className={`${styles.imgPadding} "d-flex align-items-center justify-content-center"`}>
							<StaticImage src="../../../assets/images/Group4.png" alt="IT Company" />
						</Col>
						{welcome.map((welcome, index) => {
							return (
								<Col key={index} md={6} sm={12} className="d-flex flex-column justify-content-center ps-md-5 ps-sm-0 ">
									<p className={styles.wel}>{welcome.heading}</p>
									<p className={styles.best}>{welcome.subheading}</p>
									<p className={styles.mercurysols}>{welcome.detail}</p>
								</Col>
							)
						})}
					</Row>
				</Container>
			</div>
		</>
	)
}
export default Welcome
