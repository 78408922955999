import React from "react"
import * as styles from "./styles.module.scss"
import { Card, Container, Row, Col } from "react-bootstrap"
import { navigate } from "gatsby"
import AOS from "aos"
import "aos/dist/aos.css"
import { HomePage } from "../../../constants/index"
import { Button } from "antd"

const Servicepage = () => {
	// const width = 50

	const technologies = HomePage.technologies
	const services = HomePage.services

	if (typeof window !== "undefined") {
		AOS.init({
			once: true,
			mirror: true,
		})
		window.addEventListener("load", AOS.refresh())
	}
	function cardClickHandler(techId) {
		localStorage.setItem("activePage", 2)
		navigate(`/services/#${techId}`)
	}

	return (
		<>
			<div className={styles.serve}>
				<Container>
					<Row>
						{services.map((text, index) => {
							return (
								<Col key={index}>
									<h2 className={styles.services}>{text.heading} </h2>
									<p className={styles.technology}>{text.subheading}</p>
									<p className={styles.map}> {text.detail}</p>
								</Col>
							)
						})}
					</Row>
				</Container>
			</div>
			<Row className={`m-0 ${styles.mainSblock}`} xs={2} sm={3} md={4} lg={5}>
				{technologies.map((technology, index) => {
					const clr = `${technology}`
					return (
						<Col
							key={index}
							lg={2}
							md={3}
							sm={4}
							xs={6}
							className={styles.cardpadding}
							data-aos="fade-up"
							data-aos-once="true"
							data-aos-offset="100"
							data-aos-easing="ease-in-out"
							data-aos-duration="1000"
							data-aos-mirror="true"
						>
							<Card
								className={`${styles.card} ${styles.card0}`}
								onClick={() => {
									cardClickHandler("WebDev")
								}}
								style={{ backgroundColor: `${technology.color}`, height: "264px" }}
							>
								{technology.icon}
								<Card.Body>
									<Card.Text className={styles.cardText}>{technology.title}</Card.Text>
									<Button
										style={{ backgroundColor: `${technology.background}`, textAlign: "right" }}
										className={styles.explore}
									>
										Explore
									</Button>
								</Card.Body>
							</Card>
						</Col>
					)
				})}

				<Col
					lg={2}
					md={3}
					sm={4}
					xs={6}
					className={styles.cardpadding}
					data-aos="fade-up"
					data-aos-once="true"
					data-aos-offset="100"
					data-aos-easing="ease-in-out"
					data-aos-duration="1000"
					data-aos-mirror="true"
				>
					<Card
						className={`${styles.card} ${styles.card3}`}
						onClick={() => {
							cardClickHandler("GameDev")
						}}
						style={{ height: "263px" }}
					>
						<svg
							className={styles.svg1}
							width="130"
							height="140"
							viewBox="0 0 157 157"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								className={styles.svg2}
								d="M116.682 89.8668V91.3426V139.416C116.682 143.435 113.919 146.732 110.559 146.732H45.0904C41.6992 146.732 38.9674 143.467 38.9674 139.416V44.745"
								stroke="#928F8F"
								strokeWidth="2"
							/>
							<path
								className={styles.svg2}
								d="M38.9674 44.745V22.7022C38.9674 16.9874 41.7306 12.3402 45.0904 12.3402H110.528C113.919 12.3402 116.651 16.9874 116.651 22.7022V42.2644V42.704V61.701V63.742"
								stroke="#928F8F"
								strokeWidth="2"
							/>
							<path d="M115.395 123.496H40.0978V125.977H115.395V123.496Z" fill="#928F8F" />
							<path
								d="M80.227 139.071C78.6884 139.071 77.3382 138.505 76.2706 137.438C75.1716 136.339 74.6378 135.02 74.6378 133.481C74.6378 131.943 75.203 130.593 76.2706 129.525C78.4686 127.327 82.0168 127.358 84.2148 129.525C85.3138 130.624 85.8476 131.943 85.8476 133.481C85.8476 135.02 85.2824 136.37 84.2148 137.438C83.1158 138.537 81.7656 139.071 80.227 139.071ZM80.227 130.593C79.4106 130.593 78.7826 130.875 78.186 131.44C77.6208 132.006 77.3382 132.665 77.3382 133.481C77.3382 134.298 77.5894 134.926 78.186 135.522C79.3478 136.684 81.1062 136.653 82.2366 135.522C82.8018 134.957 83.0844 134.298 83.0844 133.481C83.0844 132.665 82.8018 132.037 82.2366 131.44C81.6714 130.875 81.0434 130.593 80.227 130.593Z"
								fill="#928F8F"
							/>
							<path
								d="M69.9592 119.634C69.3626 119.634 68.9544 119.226 68.766 118.849L68.6718 118.692V118.504C68.6718 110.434 75.5484 106.729 75.8624 106.572L77.087 105.944L76.9928 115.364H76.1764C73.0678 115.364 72.2514 116.934 71.6862 118.095C71.5606 118.347 71.435 118.598 71.3094 118.786C70.9012 119.32 70.4616 119.634 69.9592 119.634ZM75.4228 108.77C73.79 109.994 70.9954 112.632 70.4302 116.902C71.0896 115.709 72.2828 113.982 75.36 113.731L75.4228 108.77Z"
								fill="#455963"
							/>
							<path
								d="M90.4948 119.634C89.9924 119.634 89.5528 119.351 89.176 118.786C89.0504 118.598 88.9248 118.347 88.7992 118.095C88.2026 116.934 87.4176 115.364 84.309 115.364H83.4926L83.3984 105.944L84.623 106.572C84.9056 106.729 91.8136 110.434 91.8136 118.504V118.692L91.7194 118.849C91.4996 119.194 91.0914 119.634 90.4948 119.634ZM85.094 113.731C88.1712 113.982 89.3644 115.709 90.0238 116.902C89.4586 112.632 86.664 109.994 85.0312 108.77L85.094 113.731Z"
								fill="#455963"
							/>
							<path
								d="M72.9422 109.209C72.9108 109.115 72.8794 108.989 72.8794 108.895C72.2514 106.132 71.9374 102.992 71.9374 99.8206C71.9374 96.8376 72.4084 93.8546 73.2876 90.903L73.476 90.3378H87.0408L87.2292 90.903C88.1398 93.8546 88.5794 96.869 88.5794 99.8206C88.5794 103.023 88.234 106.195 87.606 108.958L87.5432 109.178L85.9418 108.801L85.9732 108.613C86.5698 105.944 86.9152 102.898 86.9152 99.8206C86.9152 97.2144 86.5384 94.5454 85.7848 91.9392H74.6692C73.9156 94.5454 73.5388 97.183 73.5388 99.8206C73.5388 102.866 73.8528 105.912 74.4494 108.55C74.4808 108.644 74.5122 108.77 74.5122 108.864L72.9422 109.209Z"
								fill="#455963"
							/>
							<path
								d="M84.2148 113.699H80.227H76.2392C76.145 113.699 76.0508 113.794 76.0508 113.951V115.081C76.0508 115.207 76.145 115.332 76.2392 115.332H80.227H84.2148C84.309 115.332 84.4032 115.207 84.4032 115.081V113.951C84.4032 113.794 84.309 113.699 84.2148 113.699Z"
								fill="#455963"
							/>
							<path
								d="M80.227 102.647C77.5894 102.647 75.4542 100.511 75.4542 97.8738C75.4542 95.2362 77.5894 93.101 80.227 93.101C82.8646 93.101 84.9998 95.2362 84.9998 97.8738C84.9998 100.511 82.8646 102.647 80.227 102.647ZM80.227 94.3884C78.3116 94.3884 76.7416 95.9584 76.7416 97.8738C76.7416 99.7892 78.3116 101.359 80.227 101.359C82.1424 101.359 83.7124 99.7892 83.7124 97.8738C83.7124 95.9584 82.1424 94.3884 80.227 94.3884Z"
								fill="#455963"
							/>
							<path
								d="M80.227 96.3351C81.0748 96.3351 81.7656 97.0259 81.7656 97.8737C81.7656 98.7215 81.0748 99.4123 80.227 99.4123C79.3792 99.4123 78.6884 98.7215 78.6884 97.8737C78.6884 97.0259 79.3792 96.3351 80.227 96.3351ZM80.227 95.0477C78.657 95.0477 77.401 96.3037 77.401 97.8737C77.401 99.4437 78.657 100.7 80.227 100.7C81.797 100.7 83.053 99.4437 83.053 97.8737C83.053 96.3037 81.797 95.0477 80.227 95.0477Z"
								fill="#455963"
							/>
							<path
								d="M87.5118 91.9391H72.9736L73.2876 90.8716C74.9832 85.3138 78.186 80.6038 80.227 80.6038C82.2994 80.6038 85.4708 85.3138 87.1664 90.8716L87.5118 91.9391ZM75.1716 90.3064H85.251C83.4926 85.2196 80.9178 82.3308 80.1956 82.2366C79.5048 82.2994 76.93 85.2196 75.1716 90.3064Z"
								fill="#455963"
							/>
							<path
								d="M80.4154 109.335H80.227H80.0072C79.9758 109.335 79.9758 109.335 79.9444 109.335C79.913 109.335 79.8502 109.366 79.8188 109.398C79.599 109.46 79.1908 109.68 79.1908 110.277L79.7874 117.907V118.284V118.566C79.7874 118.661 79.8502 118.723 79.913 118.755C79.9444 118.786 79.9758 118.786 80.0072 118.786H80.1956H80.4154C80.541 118.786 80.6352 118.692 80.6352 118.566V118.284V117.876L81.2318 110.277C81.2318 109.492 80.5724 109.398 80.4468 109.366C80.4782 109.335 80.4468 109.335 80.4154 109.335Z"
								fill="#455963"
							/>
							<path
								d="M103.934 88.1399C101.893 88.1399 100.511 86.7583 100.135 84.3719C100.009 83.7439 98.4704 76.1137 102.804 68.9859C104.939 65.4691 107.639 64.7155 109.492 64.7155C112.443 64.7155 114.83 66.5053 114.924 66.5681C115.175 66.7565 115.74 66.9449 116.054 66.9449H117.781C118.095 66.9449 118.661 66.7565 118.912 66.5681C119.006 66.5053 121.392 64.7155 124.344 64.7155C126.228 64.7155 128.897 65.4691 131.064 68.9859C135.397 76.1137 133.89 83.7439 133.733 84.3719C133.324 86.7583 131.943 88.1399 129.933 88.1399C129.808 88.1399 129.682 88.1399 129.556 88.1085C127.453 87.9201 123.967 83.9323 122.931 82.7077C122.774 82.5193 122.334 82.2995 122.083 82.2995H111.69C111.439 82.2995 110.999 82.5193 110.842 82.7077C109.837 83.9323 106.352 87.9201 104.248 88.1085C104.185 88.1399 104.06 88.1399 103.934 88.1399ZM109.523 66.6309C107.514 66.6309 105.787 67.7613 104.436 69.9907C100.386 76.6475 101.987 83.9323 101.987 83.9951V84.0579C102.333 86.2559 103.526 86.2559 103.934 86.2559C103.997 86.2559 104.091 86.2559 104.154 86.2559C105.033 86.1931 107.608 83.7439 109.46 81.5459C109.994 80.9179 110.936 80.4783 111.753 80.4783H122.146C122.962 80.4783 123.936 80.9179 124.438 81.5459C126.291 83.7753 128.866 86.1931 129.745 86.2559C129.808 86.2559 129.902 86.2559 129.965 86.2559C130.373 86.2559 131.535 86.2559 131.88 84.0579V83.9951C131.911 83.9323 133.45 76.5847 129.431 69.9907C128.081 67.7613 126.354 66.6309 124.344 66.6309C122.02 66.6309 120.074 68.0753 120.042 68.1067C119.446 68.5463 118.504 68.8603 117.75 68.8917H116.023C115.269 68.8917 114.327 68.5777 113.731 68.1381C113.794 68.1067 111.815 66.6309 109.523 66.6309Z"
								fill="#455963"
							/>
							<path
								d="M109.649 78.4686H109.617C108.581 78.4372 108.047 77.872 107.985 77.8092L107.796 77.5894V75.988H106.132L105.912 75.7682C105.818 75.674 105.441 75.2344 105.378 74.3238C105.284 73.3818 105.724 72.6596 105.787 72.5654L106.006 72.22H107.89V70.3046L108.299 70.0848C108.361 70.0534 108.927 69.7708 109.712 69.7708H109.806C110.622 69.8022 111.219 70.0848 111.282 70.1162L111.69 70.336V72.22H113.385L113.605 72.5026C113.668 72.5654 114.139 73.1934 114.17 73.9784C114.202 74.7634 113.762 75.517 113.731 75.6112L113.511 75.988H111.596V77.7464L111.282 77.9662C111.219 78.029 110.591 78.4686 109.649 78.4686ZM109.335 76.93C109.398 76.9614 109.523 76.9614 109.649 76.9614V77.715V76.9614C109.806 76.9614 109.963 76.93 110.057 76.8986V74.4494H112.538C112.6 74.2924 112.632 74.1668 112.6 74.0412C112.6 73.947 112.569 73.8214 112.506 73.7272H110.088V71.3408C109.963 71.3094 109.837 71.3094 109.68 71.278H109.649C109.555 71.278 109.46 71.278 109.366 71.3094V73.7272H106.886C106.854 73.8528 106.823 74.0098 106.854 74.1668C106.854 74.2924 106.886 74.3866 106.917 74.4494H109.303V76.93H109.335Z"
								fill="#455963"
							/>
							<path
								d="M123.276 75.2972C123.622 75.2972 123.904 75.5798 123.904 75.9252C123.904 76.2706 123.622 76.5532 123.276 76.5532C122.931 76.5532 122.648 76.2706 122.648 75.9252C122.648 75.5484 122.931 75.2972 123.276 75.2972ZM123.276 74.1354C122.303 74.1354 121.518 74.9204 121.518 75.8938C121.518 76.8672 122.303 77.6522 123.276 77.6522C124.25 77.6522 125.035 76.8672 125.035 75.8938C125.035 74.9204 124.25 74.1354 123.276 74.1354Z"
								fill="#455963"
							/>
							<path
								d="M123.276 70.4302C123.622 70.4302 123.904 70.7128 123.904 71.0582C123.904 71.4036 123.622 71.6862 123.276 71.6862C122.931 71.6862 122.648 71.4036 122.648 71.0582C122.648 70.7128 122.931 70.4302 123.276 70.4302ZM123.276 69.2998C122.303 69.2998 121.518 70.0848 121.518 71.0582C121.518 72.0316 122.303 72.8166 123.276 72.8166C124.25 72.8166 125.035 72.0316 125.035 71.0582C125.035 70.0848 124.25 69.2998 123.276 69.2998Z"
								fill="#455963"
							/>
							<path
								d="M127.641 73.0364C127.986 73.0364 128.269 73.319 128.269 73.6644C128.269 74.0098 127.986 74.2924 127.641 74.2924C127.296 74.2924 127.013 74.0098 127.013 73.6644C127.044 73.319 127.296 73.0364 127.641 73.0364ZM127.641 71.906C126.668 71.906 125.883 72.691 125.883 73.6644C125.883 74.6378 126.668 75.4228 127.641 75.4228C128.614 75.4228 129.399 74.6378 129.399 73.6644C129.399 72.691 128.614 71.906 127.641 71.906Z"
								fill="#455963"
							/>
							<path
								d="M66.9762 40.0037C66.7564 40.0037 66.568 39.9723 66.3482 39.9409C66.1912 39.9723 66.0656 39.9723 65.9086 39.9723H61.387C61.0416 39.9723 60.759 39.6897 60.759 39.3443C60.759 38.9989 61.0416 38.7163 61.387 38.7163H65.8772C66.0028 38.7163 66.1284 38.6849 66.2854 38.6849C66.3482 38.6849 66.4424 38.6849 66.5052 38.6849C66.6622 38.7163 66.8192 38.7477 67.0076 38.7477C68.3892 38.7477 69.4882 37.6487 69.4882 36.2671C69.4882 34.8854 68.3892 33.7864 67.0076 33.7864C66.882 33.7864 66.7564 33.7864 66.6308 33.8178C66.4424 33.8492 66.254 33.7864 66.1284 33.6608C66.0028 33.5352 65.9086 33.3782 65.9086 33.1898V33.1584C65.9086 31.7768 64.8096 30.6778 63.428 30.6778C62.9884 30.6778 62.5488 30.8034 62.172 31.0232C62.1406 31.0546 62.1092 31.0546 62.0778 31.0546C61.7324 31.1802 61.4184 31.4 61.1358 31.6826C60.916 31.871 60.602 31.9338 60.3822 31.7768C60.0996 31.5884 59.7542 31.5256 59.4402 31.5256C58.6552 31.5256 57.9644 32.028 57.7132 32.7502C57.5876 33.0642 57.2422 33.2526 56.9282 33.127C56.6142 33.0014 56.4258 32.656 56.5514 32.342C56.991 31.1174 58.1528 30.2696 59.4716 30.2696C59.8798 30.2696 60.288 30.3638 60.6648 30.4894C60.9474 30.2696 61.2928 30.0812 61.6068 29.9242C62.172 29.6102 62.8 29.4532 63.4594 29.4532C65.312 29.4532 66.8506 30.8034 67.1332 32.5618C69.1428 32.6246 70.7756 34.2574 70.7756 36.2984C70.7128 38.3081 69.0486 40.0037 66.9762 40.0037Z"
								fill="#455963"
							/>
							<path
								d="M49.8318 41.6365C47.7594 41.6365 46.0952 39.9723 46.0952 37.8999C46.0952 35.8589 47.728 34.2261 49.7376 34.1633C50.0516 32.4049 51.5588 31.0547 53.4114 31.0547C54.0708 31.0547 54.6988 31.2117 55.264 31.5257C55.6094 31.6513 55.9234 31.8397 56.206 32.0909C56.5828 31.9339 56.991 31.8711 57.3992 31.8711C59.0948 31.8711 60.4764 33.2527 60.4764 34.9483C60.4764 35.0425 60.4764 35.1053 60.4764 35.1995C61.7324 36.0473 62.2034 37.2719 62.2034 38.1197C62.2976 40.6317 60.1624 41.5109 59.032 41.6365C59.0006 41.6365 59.0006 41.6365 58.9692 41.6365H50.9308C50.7424 41.6365 50.5854 41.6051 50.4598 41.6051C50.24 41.6365 50.0516 41.6365 49.8318 41.6365ZM49.8318 35.4193C48.4502 35.4193 47.3512 36.5183 47.3512 37.8999C47.3512 39.2815 48.4502 40.3805 49.8318 40.3805C49.9888 40.3805 50.1772 40.3491 50.3342 40.3177C50.397 40.3177 50.4912 40.2863 50.554 40.3177C50.6796 40.3491 50.8366 40.3491 50.9622 40.3491H58.9378C59.2518 40.3177 61.0102 40.0037 60.9474 38.1197C60.9474 37.6487 60.6334 36.6753 59.4716 36.0473C59.2204 35.9217 59.0948 35.6391 59.1576 35.3565C59.189 35.2309 59.189 35.0739 59.189 34.9483C59.189 33.9435 58.3726 33.1271 57.3678 33.1271C57.0224 33.1271 56.7084 33.2213 56.4258 33.3783C56.1746 33.5353 55.8606 33.5039 55.6722 33.2841C55.3896 33.0015 55.0756 32.8131 54.7302 32.6561C54.6988 32.6561 54.6674 32.6247 54.636 32.6247C54.2592 32.4049 53.8196 32.2793 53.38 32.2793C51.9984 32.2793 50.8994 33.3783 50.8994 34.7599C50.8994 34.9483 50.8366 35.1367 50.6796 35.2623C50.554 35.3879 50.3656 35.4507 50.1772 35.4193C50.083 35.4507 49.9574 35.4193 49.8318 35.4193Z"
								fill="#455963"
							/>
							<path
								d="M105.19 36.8008C105.033 36.8008 104.907 36.8008 104.75 36.7694C104.656 36.7694 104.562 36.8008 104.436 36.8008H101.296C100.951 36.8008 100.668 36.5182 100.668 36.1728C100.668 35.8274 100.951 35.5448 101.296 35.5448H104.405C104.468 35.5448 104.562 35.5448 104.656 35.5134C104.719 35.5134 104.813 35.5134 104.876 35.5134C104.97 35.5448 105.064 35.5448 105.19 35.5448C106.038 35.5448 106.697 34.854 106.697 34.0376C106.697 33.2212 106.006 32.5304 105.19 32.5304C105.127 32.5304 105.033 32.5304 104.97 32.5618C104.782 32.5932 104.593 32.5304 104.468 32.4048C104.342 32.2792 104.248 32.0908 104.248 31.9024C104.248 31.0546 103.557 30.3952 102.741 30.3952C102.458 30.3952 102.207 30.458 101.987 30.615C101.956 30.6464 101.924 30.6464 101.893 30.6464C101.673 30.7406 101.485 30.8662 101.328 31.0232C101.108 31.2116 100.794 31.2744 100.574 31.1174C100.417 31.0232 100.229 30.9604 100.009 30.9604C99.5694 30.9604 99.1612 31.243 99.0042 31.6826C98.8786 31.9966 98.5332 32.185 98.2192 32.0594C97.9052 31.9338 97.7168 31.5884 97.8424 31.2744C98.1564 30.3324 99.0356 29.7044 100.04 29.7044C100.323 29.7044 100.574 29.7358 100.825 29.83C101.014 29.7044 101.202 29.5788 101.422 29.4846C101.83 29.2648 102.301 29.1392 102.772 29.1392C104.091 29.1392 105.19 30.0498 105.473 31.3058C106.886 31.4314 108.016 32.6246 108.016 34.069C107.953 35.5448 106.697 36.8008 105.19 36.8008Z"
								fill="#455963"
							/>
							<path
								d="M93.3208 37.9312C91.7822 37.9312 90.5576 36.6752 90.5576 35.168C90.5576 33.7236 91.688 32.5304 93.101 32.4048C93.3836 31.1802 94.4826 30.2382 95.8014 30.2382C96.2724 30.2382 96.7434 30.3638 97.1516 30.5836C97.3714 30.6778 97.5598 30.772 97.7482 30.929C97.9994 30.8348 98.2506 30.8034 98.5332 30.8034C99.8206 30.8034 100.857 31.8396 100.857 33.127C100.857 33.1584 100.857 33.1584 100.857 33.1898C101.736 33.8178 102.05 34.697 102.05 35.2936C102.113 37.1776 100.543 37.837 99.695 37.8998C99.6636 37.8998 99.6636 37.8998 99.6322 37.8998H94.0744C93.9488 37.8998 93.8546 37.8998 93.7604 37.8684C93.6034 37.9312 93.4778 37.9312 93.3208 37.9312ZM93.3208 33.6294C92.473 33.6294 91.8136 34.3202 91.8136 35.1366C91.8136 35.953 92.5044 36.6438 93.3208 36.6438C93.415 36.6438 93.5092 36.6438 93.6348 36.6124C93.6976 36.6124 93.7918 36.6124 93.8546 36.6124C93.9488 36.6124 94.043 36.6438 94.1372 36.6438H99.6322C99.8834 36.6124 100.888 36.424 100.825 35.2936C100.825 35.2308 100.825 34.5086 99.9148 34.0062C99.6636 33.8806 99.538 33.598 99.6008 33.3154C99.6322 33.2212 99.6322 33.1584 99.6322 33.0642C99.6322 32.4676 99.1612 31.9966 98.5646 31.9966C98.3762 31.9966 98.1878 32.0594 97.9994 32.1536C97.7482 32.3106 97.4342 32.2792 97.2458 32.0594C97.0574 31.9024 96.869 31.7454 96.6806 31.6826C96.6492 31.6826 96.6178 31.6512 96.5864 31.6512C96.3666 31.5256 96.084 31.4314 95.8328 31.4314C94.985 31.4314 94.3256 32.1222 94.3256 32.9386C94.3256 33.127 94.2314 33.3154 94.1058 33.441C93.9802 33.5666 93.7918 33.6294 93.6034 33.598C93.4778 33.6294 93.3836 33.6294 93.3208 33.6294Z"
								fill="#455963"
							/>
							<path
								d="M75.2972 30.0498C73.4132 30.0498 71.8746 28.5112 71.8746 26.6272C71.8746 24.7746 73.3504 23.2674 75.1716 23.2046C75.4542 21.6032 76.8672 20.41 78.5314 20.41C79.128 20.41 79.6932 20.567 80.227 20.8496C80.541 20.9752 80.7922 21.1322 81.0748 21.352C81.4202 21.2264 81.7656 21.1636 82.1424 21.1636C83.7124 21.1636 84.9684 22.451 84.9684 23.9896C84.9684 24.0524 84.9684 24.1152 84.9684 24.178C86.0988 24.963 86.5384 26.0934 86.5384 26.847C86.6326 29.1706 84.6544 29.987 83.6182 30.0812C83.5868 30.0812 83.5868 30.0812 83.5554 30.0812H76.302C76.145 30.0812 75.988 30.0498 75.8624 30.0498C75.674 30.0184 75.4856 30.0498 75.2972 30.0498ZM75.2972 24.4292C74.104 24.4292 73.1306 25.4026 73.1306 26.5958C73.1306 27.789 74.104 28.7624 75.2972 28.7624C75.4542 28.7624 75.5798 28.7624 75.7368 28.731C75.7996 28.731 75.8938 28.6996 75.9566 28.731C76.0822 28.7624 76.2078 28.7624 76.3334 28.7624H83.524C83.8066 28.731 85.3452 28.4484 85.2824 26.8156C85.2824 26.376 84.9998 25.5596 83.995 24.9944C83.7438 24.8688 83.6182 24.5862 83.681 24.3036C83.7124 24.178 83.7124 24.0524 83.7124 23.9582C83.7124 23.079 82.9902 22.3882 82.1424 22.3882C81.8598 22.3882 81.5772 22.4824 81.326 22.608C81.0748 22.765 80.7608 22.7336 80.5724 22.5138C80.3212 22.2626 80.0386 22.1056 79.756 21.98C79.7246 21.98 79.6932 21.9486 79.6618 21.9486C79.3164 21.7602 78.9396 21.666 78.5628 21.666C77.3696 21.666 76.3962 22.6394 76.3962 23.8326C76.3962 24.021 76.302 24.2094 76.1764 24.335C76.0508 24.4606 75.8624 24.5234 75.674 24.492C75.517 24.4292 75.3914 24.4292 75.2972 24.4292Z"
								fill="#455963"
							/>
						</svg>
						<Card.Body>
							<Card.Text className={styles.cardText}>Game Development</Card.Text>
						</Card.Body>
						<Button style={{ backgroundColor: "#8874A3 " }} className={styles.explore}>
							Explore
						</Button>
					</Card>
				</Col>
			</Row>
			<div className={styles.btnSDiv}>
				<button
					className={styles.ViewSbutton1}
					variant="primary"
					type="submit"
					onClick={() => {
						navigate("/contactus")
					}}
				>
					CONTACT US
				</button>
			</div>
		</>
	)
}
export default Servicepage
